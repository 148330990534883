<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Tasks' | abpLocalization }}</h1>
    </div>

    <div class="col pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <div class="col" *abpPermission="'Tickets.Tickets.Create'">
        <div class="text-right float-end">
            <button
                id="new-task"
                class="btn btn-primary"
                (click)="createTicket()"
                type="button">
                <i class="me-1 fa fa-plus"></i>
                <span>{{ '::NewTask' | abpLocalization }}</span>
            </button>
        </div>
    </div>
</div>

<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="" >
        <abp-advanced-entity-filters-form>
            <form [formGroup]="filterForm">
                <div class="row form-group">
                    <div class="col-12 col-sm-3" *ngIf="!isUserEmployee">
                        <div class="mb-3">
                            <label for="taskUser" class="form-label">
                                {{ '::AssignedTo' | abpLocalization }}
                            </label>
                            <select id="taskUser" class="form-select form-control" formControlName="assignedUserId" (ngModelChange)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="user.id" *ngFor="let user of tenantUsers$ | async">{{ user.userName }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3">
                        <div class="mb-3">
                            <label for="taskStatus" class="form-label">
                                {{ '::Status' | abpLocalization }}
                            </label>
                            <select id="taskStatus" class="form-select form-control" formControlName="taskStatus" (ngModelChange)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="status.value" *ngFor="let status of statusTypes">
                                    {{ '::Enum:TaskStatus:' + status.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3" *ngIf="!isUserEmployee">
                        <div class="mb-3">
                            <label for="ticketKind" class="form-label">
                                {{ '::Kind' | abpLocalization }}
                            </label>
                            <select id="ticketKind" class="form-select form-control" formControlName="taskKind" (ngModelChange)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="kind.value" *ngFor="let kind of ticketKinds">
                                    {{ '::Enum:TaskKind:' + kind.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3" *ngIf="!isUserEmployee">
                        <div class="mb-3">
                            <label for="educationKind" class="form-label">
                                {{ '::EducationKind' | abpLocalization }}
                            </label>
                            <select id="educationKind" class="form-select form-control" formControlName="taskEducationKind" (ngModelChange)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="educationKind.value" *ngFor="let educationKind of ticketEducationKinds">
                                    {{ '::Enum:EducationKind:' + educationKind.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-12">
                        <div class="form-group">
                            <div>
                                <label for="ticket-document" class="form-label">
                                    {{ '::SearchByDocument' | abpLocalization }}
                                </label>
                            </div>

                            <abp-lookup-typeahead
                                cid="ticket-document"
                                displayNameProp="title"
                                [getFn]="tenantDocumentLookupService.getTenantDocumentsLookup"
                                [(ngModel)]="filters.tenantDocumentId"
                                [ngModelOptions]="{ standalone: true }"
                            ></abp-lookup-typeahead>
                        </div>
                    </div>

                    <div class="col-lg-3 col-12">
                        <div class="form-group">
                            <div>
                                <label for="ticket-course" class="form-label">
                                    {{ '::SearchByCourse' | abpLocalization }}
                                </label>
                            </div>

                            <abp-lookup-typeahead
                                cid="ticket-course"
                                displayNameProp="title"
                                [getFn]="tenantCourseLookupService.getTenantCoursesLookup"
                                [(ngModel)]="filters.tenantCourseId"
                                [ngModelOptions]="{ standalone: true }"
                            ></abp-lookup-typeahead>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3">
                        <div class="mb-3">
                            <label for="startDateTime" class="form-label">
                                {{ '::StartDateTime' | abpLocalization }}
                            </label>
                            <input
                                #datepickerStart="ngbDatepicker"
                                class="form-control"
                                name="datepicker"
                                formControlName="startDate"
                                ngbDatepicker
                                (ngModelChange)="getListWithFilters()"
                                (click)="datepickerStart.toggle()"
                            />
                            <ngb-timepicker
                                [abpDisabled]="!this.filterForm.get('startDate').value"
                                formControlName="startTime"
                                (ngModelChange)="getListWithFilters()">
                            </ngb-timepicker>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3">
                        <div class="mb-3">
                            <label for="endDateTime" class="form-label">
                                {{ '::EndDateTime' | abpLocalization }}
                            </label>
                            <input
                                #datepickerEnd="ngbDatepicker"
                                class="form-control"
                                id="endDateTime"
                                name="datepicker1"
                                ngbDatepicker
                                formControlName="endDate"
                                (ngModelChange)="getListWithFilters()"
                                (click)="datepickerEnd.toggle()"
                            />
                            <ngb-timepicker
                                [abpDisabled]="!this.filterForm.get('endDate').value"
                                formControlName="endTime"
                                (ngModelChange)="getListWithFilters()">
                            </ngb-timepicker>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3" *ngIf="!isUserEmployee">
                        <div class="mb-3">
                            <label for="organization-unit-filter" class="form-label">
                                {{ '::OrganizationUnit' | abpLocalization }}
                            </label>
                            <ng-multiselect-dropdown
                                name="organization-unit"
                                placeholder="{{ '::Select' | abpLocalization }}"
                                id="ng-organization-unit-filter"
                                [data]="dropdownList"
                                formControlName="organizationUnitId"
                                (onSelect)="onOrganizationUnitSelect($event)"
                                (onDeSelect)="onOrganizationUnitDeSelect($event)"
                                (onFilterChange)="organizationUnitFilterChange($event)"
                                [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-sm-auto align-self-start mb-3">
                    <div class="row">
                        <div class="col-6 col-sm-auto d-grid">
                            <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                                <span>{{ '::Clear' | abpLocalization }}</span>
                            </button>
                        </div>
                        <div class="col-6 col-sm-auto d-grid">
                            <button type="button" class="btn btn-primary" (click)="list.get()">
                                <span>{{ '::Refresh' | abpLocalization }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div class="card">
    <div class="card-body">
        <ngx-datatable 
            [rows]="task.items" 
            [count]="task.totalCount"
            [list]="list"
            default>
            <ngx-datatable-column
                [name]="'::Actions' | abpLocalization"
                [maxWidth]="140"
                [sortable]="false"
                *abpPermission="'Tickets.Tickets.Edit || Tickets.Tickets.Delete || Tickets.Tickets.View'"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div ngbDropdown container="body">
                        <button
                            class="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            ngbDropdownToggle
                        >
                            <i class="fa fa-cog mr-1"></i>&nbsp;{{ '::Actions' | abpLocalization }}
                        </button>
                        <div ngbDropdownMenu>
                            <button
                                ngbDropdownItem
                                *ngIf="isCourseAvailable(row)"
                                (click)="navigateToAssignedCourse(row)"
                            >
                                {{ '::Navigate:To:Course' | abpLocalization }}
                            </button>
                            <button
                                ngbDropdownItem
                                *ngIf="isDocumentAvailable(row)"
                                (click)="navigateToAssignedDocument(row)"
                            >
                                {{ '::Navigate:To:Document' | abpLocalization }}
                            </button>
                            <button
                                ngbDropdownItem
                                *abpPermission="'Tickets.Tickets.Edit'"
                                (click)="editTicket(row.id)"
                            >
                                {{ '::Edit' | abpLocalization }}
                            </button>
                            <button 
                                ngbDropdownItem
                                (click)="deleteTicket(row.id)"
                                *abpPermission="'Tickets.Tickets.Delete'"
                            >
                                {{ '::Delete' | abpLocalization }}
                            </button>
                        </div>
                    </div>
                </ng-template>
                
            </ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::Name' | abpLocalization" prop="name"></ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::Description' | abpLocalization" prop="description"></ngx-datatable-column>
            
            <ngx-datatable-column 
                *ngIf="!isUserEmployee"
                [maxWidth]="150" 
                [name]="'::AssignedUserName' | abpLocalization" 
                prop="assignedUserName">
            </ngx-datatable-column>
            
            <ngx-datatable-column [maxWidth]="150" [name]="'::Status' | abpLocalization" prop="status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ '::Enum:TaskStatus:' + row.status | abpLocalization }}
                </ng-template>
                
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'::TaskKind' | abpLocalization" prop="ticketKind">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.ticketKind !== null  ? ('::Enum:TaskKind:' + row.ticketKind | abpLocalization) : "" }}
                </ng-template>
                
            </ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::StartDateTime' | abpLocalization" prop="startDateTime" [maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.startDateTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::EndDateTime' | abpLocalization" prop="endDateTime" [maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.endDateTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column 
                *ngIf="!isUserEmployee"
                [name]="'::CreationTime' | abpLocalization" 
                prop="creationTime" 
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.creationTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ (selectedTicket.id ? '::EditTask' : '::NewTask') | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="createUpdateTicketForm" (ngSubmit)="saveTicket()">
            <div class="form-group mb-3">
                <label for="task-name" class="form-label">{{ '::Name' | abpLocalization }} </label><span> *</span>
                <input type="text" id="task-name" class="form-control" formControlName="name">
            </div>
            
            <div class="form-group mb-3">
                <label for="task-description" class="form-label">{{ '::Description' | abpLocalization }}</label>
                <textarea id="task-description" class="form-control" cols="30" rows="4" formControlName="description"></textarea>
            </div>

            <div *ngIf="isTicketForEdit()">
                <div class="form-group mb-3">
                    <label for="task-user" class="form-label"> {{ '::AssignedTo' | abpLocalization }} </label> <span> *</span>
                    <select id="task-user" class="form-select form-control" formControlName="assignedUserId" [abpDisabled]="isTicketDocumentApprovalSelected">
                        <option value=""></option>
                        <option [ngValue]="user.id" *ngFor="let user of tenantUsers$ | async">{{ user.userName }}</option>
                    </select>
                </div>
            </div>

            <div *ngIf="!isTicketForEdit()">

                <div class="form-group mb-3">
                    <label for="select-from-switch">
                        {{ '::SelectUsersFrom' |  abpLocalization }}
                    </label>
                    <select 
                        id="select-from-switch" 
                        class="form-select form-control" 
                        (change)="onSelectionFromValueChange($event.target.value)"
                    >
                        <option value=""></option>
                        <option value="true" >
                            {{ '::Enum:SelectUsersFrom:Users' | abpLocalization }}
                        </option>
                        <option value="false" >
                            {{ '::Enum:SelectUsersFrom:OrganizationUnits' | abpLocalization }}
                        </option>
                    </select>

                </div>

                <div class="form-group mb-3" *ngIf="selectFromUsers">
                    <label class="form-label" for="ticket-users-id">
                        {{ '::Users' | abpLocalization }}
                    </label><span> *</span>

                    <input
                        class="form-control" 
                        id="ticket-users-id"
                        type="search"
                        formControlName="assignedUserId"
                        [editable]="false"
                        [resultFormatter]="tenantFormatter"
                        [inputFormatter]="tenantFormatter"
                        [ngbTypeahead]="searchTenantForTicket"
                        (focus)="focusForTicketTenant$.next($any($event).target.value)"
                        (click)="clickForTicketTenant$.next($any($event).target.value)"
                        (ngModelChange)="selectedNewTaskUsers($event)"
                        #instance="ngbTypeahead"
                        [abpDisabled]="disableTicketStatusField()"
                    />
                    <br/>
                    <button 
                        type="button" 
                        class="btn btn-primary"
                        (click)="selectAllTenantUsers()"
                        [disabled]="disableTicketStatusField()"
                    >
                        {{ '::SelectAll' | abpLocalization }}
                    </button>
                </div>

                <div class="form-group mb-3" *ngIf="selectFromOrganizationUnit">
                    <label class="form-label" for="organization-unit-create">
                        {{ '::OrganizationUnit' | abpLocalization}}
                    </label><span> *</span>

                    <ng-multiselect-dropdown
                        name="organization-unit"
                        id="ng-organization-unit-create"
                        [data]="dropdownList"
                        formControlName="organizationUnitId"
                        (onSelect)="onOrganizationUnitCreateSelect($event)"
                        (onDeSelect)="onOrganizationUnitCreateDeSelect($event)"
                        (onFilterChange)="organizationUnitFilterChange($event)"
                        [settings]="dropdownSettings">
                    </ng-multiselect-dropdown>

                    <br/>
                    <button 
                        type="button" 
                        class="btn btn-primary"
                        (click)="selectUsersFromSelectedOrganizationUnit()"
                    >
                        {{ '::Select' | abpLocalization }}
                    </button>
                </div>

                <ngx-datatable 
                    [rows]="selectedUsers.items" 
                    [count]="selectedUsers.totalCount" 
                    [limit]="5"
                    #width
                    default
                >

                    <ngx-datatable-column
                        [name]="'::Actions' | abpLocalization"
                        [maxWidth]="80"
                        [sortable]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div container="body" class="d-inline-block">
                            <button
                                class="btn btn-danger btn-sm"
                                aria-haspopup="true"
                                (click)="removeAssignedUser(row)"
                                type="button"
                                [disabled]="disableTicketStatusField()"
                            >
                                <i class="fa fa-trash"></i>
                            </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        [name]="'::UserName' | abpLocalization"
                        prop="userName"
                    >
                    </ngx-datatable-column>

                </ngx-datatable>

            </div>

            <div class="form-group mb-3">
                <label for="task-status" class="form-label">{{ '::Status' | abpLocalization }}</label> <span> *</span>
                <select 
                    id="task-status" 
                    class="form-select form-control" 
                    formControlName="taskStatus" 
                    [abpDisabled]="disableTicketStatusField()"
                    >
                    <option value=""></option>
                    <option [ngValue]="status.value" *ngFor="let status of statusTypes">
                        {{ '::Enum:TaskStatus:' + status.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="task-start-time">{{ '::StartDateTime' | abpLocalization }} </label><span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input 
                            id="task-start-time"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            formControlName="startDate"
                            ngbDatepicker
                            (click)="datepickerStart.toggle()"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            [abpDisabled]="!this.createUpdateTicketForm.get('startDate').value"
                            formControlName="startTime"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="task-end-time">{{ '::EndDateTime' | abpLocalization }}</label> <span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input
                            id="task-end-time"
                            #datepickerEnd="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerEnd"
                            formControlName="endDate"
                            ngbDatepicker
                            (click)="datepickerEnd.toggle()"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            [abpDisabled]="!this.createUpdateTicketForm.get('endDate').value"
                            formControlName="endTime"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label for="task-kind" class="form-label">{{ '::TaskKind' | abpLocalization }}</label> <span> *</span>
                <select 
                    id="task-kind" 
                    class="form-select form-control" 
                    formControlName="ticketKind"
                >
                    <option [ngValue]="null"></option>
                    <option [ngValue]="kind.value" *ngFor="let kind of ticketKinds">
                        {{ '::Enum:TaskKind:' + kind.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3" [hidden]="!isTicketEducationKindShown">
                <label for="task-kind" class="form-label">{{ '::TicketEducationKind' | abpLocalization }}</label><span> *</span>
                <select
                    id="task-kind" 
                    class="form-select form-control" 
                    formControlName="ticketEducationKind"
                    (change)="getSelectedTaskEducationKind($event.target.value)"
                >
                    <option value=""></option>
                    <option [value]="educationKind.value" *ngFor="let educationKind of ticketEducationKinds">
                        {{ '::Enum:EducationKind:' + educationKind.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3" [hidden]="!isTicketDocumentApprovalSelected">

                <div [hidden]="!isDocumentApproval()">
                    <label for="task-document-approval" class="form-label"> 
                        {{ '::SelectDocumentForApproval' | abpLocalization }}
                    </label>
                    <span [hidden]="!documentHasRequiredValidator()"> *</span>
                    <abp-lookup-typeahead
                        cid="task-document-approval"
                        formControlName="document"
                        displayNameProp="displayName"
                        [getFn]="tenantDocumentLookupService.getTenantDocumentsWithApprovalDataLookup"
                        [filter]="selectedTicket?.document?.name"
                    ></abp-lookup-typeahead>
                </div>

                <div [hidden]="!isCourseApproval()">
                    <label for="task-course-approval" class="form-label"> 
                        {{ '::SelectCourseForApproval' | abpLocalization }}
                    </label>
                    <span [hidden]="!courseHasRequiredValidator()"> *</span>

                    <abp-lookup-typeahead
                        cid="task-course-approval"
                        formControlName="course"
                        displayNameProp="displayName"
                        [getFn]="tenantCourseLookupService.getTenantCoursesWithApprovalDataLookup"
                        [filter]="selectedTicket?.course?.name"
                    ></abp-lookup-typeahead>
                </div>

            </div>

            <div class="form-group mb-3" [hidden]="!isDocumentSelectShown()">
                <label for="task-document" class="form-label"> 
                    {{ '::SelectDocumentForTask' | abpLocalization }}
                </label>
                <span *ngIf="documentHasRequiredValidator()"> *</span>
                <abp-lookup-typeahead
                    cid="task-course"
                    formControlName="document"
                    displayNameProp="displayName"
                    [getFn]="tenantDocumentLookupService.getTenantDocumentsLookup"
                    [filter]="selectedTicket?.course?.name"
                >
                </abp-lookup-typeahead>
            </div>

            <div class="form-group mb-3" [hidden]="!isCourseSelectShown()">
                <label for="task-course" class="form-label"> 
                    {{ '::SelectCourseForTask' | abpLocalization }} 
                </label>
                <span *ngIf="courseHasRequiredValidator()"> *</span>

                <abp-lookup-typeahead
                    cid="task-course"
                    formControlName="course"
                    displayNameProp="displayName"
                    [getFn]="tenantCourseLookupService.getTenantCoursesLookup"
                    [filter]="selectedTicket?.course?.name"
                >
                </abp-lookup-typeahead>
            </div>

        </form>
    </ng-template>
    
    <ng-template #abpFooter>
        <button type="button" class="btn btn-secondary" abpClose>
            {{ '::Close' | abpLocalization}}
        </button>

        <button 
            class="btn btn-primary" 
            (click)="saveTicket()"
            [disabled]="createUpdateTicketForm.invalid"
        >
            {{ '::Save' | abpLocalization}}
        </button>
    </ng-template>
</abp-modal>