import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BaseDocumentDto, TenantDocumentWithApprovalDataDto } from '../documents/models';
import type { LookupDto, LookupRequestDto } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class TenantDocumentLookupService {
  apiName = 'Default';
  

  getTenantDocumentLookup = () =>
    this.restService.request<any, ListResultDto<BaseDocumentDto>>({
      method: 'GET',
      url: '/api/app/tenant-document-lookup/tenant-document-lookup',
    },
    { apiName: this.apiName });
  

  getTenantDocumentWithApprovalDataLookup = () =>
    this.restService.request<any, ListResultDto<TenantDocumentWithApprovalDataDto>>({
      method: 'GET',
      url: '/api/app/tenant-document-lookup/tenant-document-with-approval-data-lookup',
    },
    { apiName: this.apiName });
  

  getTenantDocumentsLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/tenant-document-lookup/tenant-documents-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getTenantDocumentsWithApprovalDataLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/tenant-document-lookup/tenant-documents-with-approval-data-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
