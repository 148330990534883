<div class="row entry-row">
  <div class="col-12 col-sm-auto">
    <h1 class="content-header-title">{{ '::Menu:E-Documents' | abpLocalization }}</h1>
  </div>

  <div class="col pl-lg-0">
    <abp-breadcrumb></abp-breadcrumb>
  </div>

  <ng-container *ngIf="getDocumentControlFeature()">

    <div class="col">

      <div *abpPermission="'Tickets.Tickets.Create || E-Document.Documents.Create'">
        <div id="AbpContentToolbar" class="float-end" *ngIf="isMultiSelectDataSelected()">
          <button
            (click)="openCreateDocumentMultiselectTaskModal()"
            class="btn btn-sm btn-primary m-2"
            type="button"
            *ngIf="isCurrentTenantUserAdminOrDSSSpecialist()"
          >
            <span>{{ '::CreateTask' | abpLocalization }}</span>
          </button>
          <button
            (click)="openUploadDocumentsModal()"
            class="btn btn-sm btn-primary m-2"
            type="button"
            *ngIf="isCurrentUserSuperAdmin()"
          >
            <i class="me-2 fa fa-upload" aria-hidden="true"></i>
            <span>{{ '::UploadDocumentsForTenant' | abpLocalization }}</span>
          </button>
        </div>
      </div>

      <div id="AbpContentToolbar" class="text-right float-end">
        <button
          (click)="createDocument()"
          class="btn btn-sm btn-primary m-2"
          type="button"
          id="new-document"
          *abpPermission="'E-Document.Documents.Create'"
        >
          <i class="me-1 fa fa-plus"></i>
          <span>{{ '::NewDocument' | abpLocalization }}</span>
        </button>
      </div>

    </div>

  </ng-container>

</div>

<div class="data-tables-filter text-start">
  <abp-advanced-entity-filters [list]="list" localizationSourceName="">
    <abp-advanced-entity-filters-form>
      <form [formGroup]="filterForm">
        <div class="row">

          <div class="col-12 col-sm-4 mb-2">
            <div class="form-group">
              <label for="typeahead-focus-category" class="form-label">
                {{ '::SearchByCategory' | abpLocalization }}
              </label>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                id="typeahead-focus-category"
                type="search"
                class="form-control"
                [editable]="false"
                (selectItem)="selectedItem($event)"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
                formControlName="category"
                (input)="getListWhenCategoryInputEmpty($event.target.value)"
                [ngbTypeahead]="searchDocumentByCategory"
                (focus)="focusForCategoryFilter$.next($any($event).target.value)"
                (click)="clickForCategoryFilter$.next($any($event).target.value)"
                #instance="ngbTypeahead"
              />
            </div>
          </div>

          <div class="col-12 col-sm-4 mb-3">
            <div class="form-group">
              <label for="typeahead-focus-test" class="form-label">
                {{ '::SearchByTest' | abpLocalization }}
              </label>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                id="typeahead-focus-test"
                type="search"
                class="form-control"
                [editable]="false"
                (selectItem)="selectedTestItem($event)"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
                formControlName="test"
                (input)="getListWhenTestInputEmpty($event.target.value)"
                [ngbTypeahead]="searchDocumentByTest"
                (focus)="focusForTestFilter$.next($any($event).target.value)"
                (click)="clickForTestFilter$.next($any($event).target.value)"
                #instance="ngbTypeahead"
              />
            </div>
          </div>

          <div class="col-12 col-sm-4 mb-3" *ngIf="showAvailableUntilField()">
            <div class="form-group">
              <label for="available-date" class="form-label">
                {{ '::AvailableUntilDate' | abpLocalization }}
              </label>
            </div>
            <div class="input-group">
              <input 
                id="available-date"
                #datepickerStart="ngbDatepicker"
                class="form-control"
                name="datepickerStart"
                formControlName="availableUntil"
                ngbDatepicker
                (click)="datepickerStart.toggle()"
              >                                
            </div>
          </div>

          <div class="col-12 col-sm-4 mb-3">
            <div class="form-group">
              <label for="creation-date-from" class="form-label">
                {{ '::CreationDateFrom' | abpLocalization }}
              </label>
            </div>
            <div class="input-group">
              <input 
                id="creation-date-from"
                #datepickerCreationTimeFrom="ngbDatepicker"
                class="form-control"
                name="datepickerCreationTimeFrom"
                formControlName="creationTimeFrom"
                ngbDatepicker
                (click)="datepickerCreationTimeFrom.toggle()"
              >                                
            </div>
          </div>

          <div class="col-12 col-sm-4 mb-3">
            <div class="form-group">
              <label for="creation-date-to" class="form-label">
                {{ '::CreationDateTo' | abpLocalization }}
              </label>
            </div>
            <div class="input-group">
              <input 
                id="creation-date-to"
                #datepickerCreationTimeTo="ngbDatepicker"
                class="form-control"
                name="datepickerCreationTimeTo"
                formControlName="creationTimeTo"
                ngbDatepicker
                (click)="datepickerCreationTimeTo.toggle()"
              >                                
            </div>
          </div>

          <div class="col-12 col-sm-4 mb-3" *ngIf="currentUserTenantId != null">
            <div class="form-group">
              <label for="task-status" class="form-label">
                {{ '::SearchByDocument' | abpLocalization }}
              </label>
            </div>
            <div class="input-group">
              <select 
                id="task-status" 
                class="form-select form-control" 
                formControlName="documentStatus"
                (change)="getListWithFilters()"
              > 
                <option value=""></option>
                <option [ngValue]="status.value" *ngFor="let status of documentStatusTypes">
                    {{ '::Enum:DocumentApprovalStatus:' + status.value | abpLocalization }}
                </option>
            </select>                 
            </div>
          </div>

          <div class="col-12 col-sm-4 mb-3" *ngIf="isAdmin">
            <div class="form-group">
                <label for="typeahead-focus-user" class="form-label">
                    {{ '::SearchByAuthor' | abpLocalization }}
                </label>
            </div>
            <div class="input-group">
                <input 
                  class="form-control"
                  id="typeahead-focus-user"
                  type="search"
                  [editable]="false"
                  (selectItem)="selectedUser($event)"
                  [resultFormatter]="userFormatter"
                  [inputFormatter]="userFormatter"
                  formControlName="creatorId"
                  (input)="getListWhenUserInputEmpty($event.target.value)"
                  [ngbTypeahead]="searchDocumentByUser"
                  (focus)="focusForUserSearch$.next($any($event).target.value)"
                  (click)="clickForUserSearch$.next($any($event).target.value)"
                  #instance="ngbTypeahead"
                />
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-auto align-self-start mt-3">
          <div class="row">
            <div class="col-6 col-sm-auto d-grid">
              <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                <span>{{ '::Clear' | abpLocalization }}</span>
              </button>
            </div>
            <div class="col-6 col-sm-auto d-grid">
              <button type="button" class="btn btn-primary" (click)="list.get()">
                <span>{{ '::Refresh' | abpLocalization }}</span>
              </button>
            </div>
          </div>
        </div>

      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>
</div>

<div *abpPermission="'E-Document.Documents.ViewProductSuggestions'">
  <app-document-banner 
    *ngIf="tenantHasMissingDocuments" 
    [documents]="missingDocuments" 
    [ecommerceUrl]="ecommerceUrl"
  ></app-document-banner>  
</div>

<div class="card">
  <div class="card-body">
    <ngx-datatable 
        [rows]="tenantDocument.items" 
        [count]="tenantDocument.totalCount" 
        [list]="list" 
        default
    >

      <ngx-datatable-column
        [name]="'::Actions' | abpLocalization"
        [sortable]="false"
        [maxWidth]="150"
        *abpPermission="'E-Document.Documents.Edit || E-Document.Documents.Delete || E-Document.Documents.Copy || E-Document.Documents.View'"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div ngbDropdown container="body" class="d-inline-block">
            <button
              class="btn btn-primary btn-sm dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              ngbDropdownToggle
            >
              <i class="fa fa-cog mr-1"></i>
              {{ '::Actions' | abpLocalization }}
            </button>
            <div ngbDropdownMenu>

              <ng-container *ngIf="getDocumentViewFeature()">
                
                <button
                  ngbDropdownItem
                  *abpPermission="'E-Document.Documents.View'"
                  (click)="viewDocumentPage(row.id, row.tenantDocumentId)"
                >
                  {{ '::ViewDocument' | abpLocalization }}
                </button>

                <button
                  *ngIf="ecommerceUrl && row.productUrlPath"
                  ngbDropdownItem
                  (click)="goToDocumentProductPage(row.productUrlPath)"
                >
                  {{ '::DocumentProductUrlPage' | abpLocalization }}
                </button>

                <ng-container *ngIf="getTestAttemptFeature()">
                    <div *abpPermission="'Tests.Tests.Attempt'">
                      <button
                        *ngIf="row.test"
                        ngbDropdownItem
                        (click)="attemptDocumentTest(row.test.id, isTenantExist() ? row.tenantDocumentId : row.id)"
                      >
                        {{ '::TakeATest' | abpLocalization }}
                      </button>
                    </div>
                </ng-container>
                
              </ng-container>

              <ng-container *ngIf="getDocumentControlFeature()">
                <ng-container *ngIf="isShownForCopy(row.id)">
                  <button
                    ngbDropdownItem
                    *abpPermission="'E-Document.Documents.Copy'"
                    (click)="copyDocument(row.id)"
                  >
                    {{ '::Copy' | abpLocalization }}
                  </button>
                </ng-container>

                <ng-container *ngIf="isShownForAssignByReference(row.id)">
                  <button
                    ngbDropdownItem
                    *abpPermission="'E-Document.Documents.AssignByReference'"
                    (click)="assignByReference(row.id)"
                  >
                    {{ '::ProvideAccess' | abpLocalization }}
                  </button>
                </ng-container>

                <ng-container *ngIf="showCreateApprovalTaskActionButton(row.status)">
                  <button
                    ngbDropdownItem
                    (click)="createTaskForDocumentApproval(row.tenantDocumentId)"
                  >
                    {{ '::CreateDocumentApprovalTask' | abpLocalization }}
                  </button>
                </ng-container>

                <ng-container *ngIf="isShown(row.tenantId)">
                  <button
                    ngbDropdownItem
                    *abpPermission="'E-Document.Documents.Edit'"
                    (click)="editDocument(row.id, row.tenantDocumentId)"
                  >
                    {{ '::Edit' | abpLocalization }}
                  </button>
                </ng-container>

                <ng-container *ngIf="isShown(row.tenantId)">
                  <button
                    ngbDropdownItem
                    *abpPermission="'E-Document.Documents.Delete'"
                    (click)="deleteDocument(row.id)"
                  >
                    {{ '::Delete' | abpLocalization }}
                  </button>
                </ng-container>                
              </ng-container>

            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [sortable]="false"
        [maxWidth]="60"
        *ngIf="isCurrentTenantUserAdminOrDSSSpecialist() || isCurrentUserSuperAdmin()"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="form-check form-check-inline">
            <input 
              *ngIf="isCurrentTenantUserAdminOrDSSSpecialist()"
              class="form-check-input" 
              type="checkbox" 
              (change)="changeDocumentSelection(row, $event.target.checked)"
              [checked]="isDocumentChecked(row.tenantDocumentId)"
            >
            <input 
              *ngIf="isCurrentUserSuperAdmin()"
              class="form-check-input" 
              type="checkbox" 
              (change)="changeDocumentSelection(row, $event.target.checked)"
              [checked]="isDocumentChecked(row.id)"
            >
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [name]="'::Name' | abpLocalization"
        prop="name"
      ></ngx-datatable-column>

      <ngx-datatable-column
        [name]="'::Category' | abpLocalization"
        prop="category.name"
      ></ngx-datatable-column>

      <ngx-datatable-column
        [name]="'::Description' | abpLocalization"
        prop="description"
      >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ getTruncatedDescription(row.description) }}
      </ng-template>
      </ngx-datatable-column> 

      <ngx-datatable-column
        [name]="'::Test' | abpLocalization"
        prop="test.name"
      ></ngx-datatable-column>

      <ngx-datatable-column
        [name]="'::Code' | abpLocalization"
        prop="code"
        [maxWidth]="150"
        [sortable]="false"
      ></ngx-datatable-column>

      <ngx-datatable-column
        *ngIf="isCurrentUserImpersonatedSuperAdmin()"
        [name]="'::IsUploaded' | abpLocalization"
        prop="isUpload"
        [maxWidth]="150"
        [sortable]="false"   
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.isUpload ? ('::Yes' | abpLocalization) : '::No' | abpLocalization }}
        </ng-template>  
      </ngx-datatable-column>

      <ngx-datatable-column 
        *ngIf="isAdmin"
        [name]="'::Modifier' | abpLocalization" 
        prop="modifier"
        [sortable]="false"
      ></ngx-datatable-column>

      <ngx-datatable-column 
        *ngIf="isAdmin"
        [name]="'::ModificationTime' | abpLocalization" 
        prop="modificationTime"
        [maxWidth]="200"
        [sortable]="false"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.lastModificationTime | date: dateFormat }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column 
        *ngIf="isAdmin"
        [name]="'::Creator' | abpLocalization" 
        prop="creator"
        [sortable]="false"
      ></ngx-datatable-column>

      <ngx-datatable-column 
        [name]="'::CreationDate' | abpLocalization" 
        prop="creationTime"
        [maxWidth]="200"
        [sortable]="false"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.creationTime | date: dateFormat }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column 
        *ngIf="currentUserTenantId != null"
        [name]="'::ApprovalDate' | abpLocalization" 
        prop="approvalDate"
        [maxWidth]="200"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.approvalDate | date: dateFormat }}
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </div>
</div>

<abp-modal [(visible)]="isModalOpen" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>
      {{
        (selectedDocument.id ? (isView ? '::ViewDocumentHeader' : '::EditDocument') : '::NewDocument') | abpLocalization
      }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form" (ngSubmit)="saveDocument()">
      <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="main-tab0"
            type="button"
            role="tab"
            (click)="setActiveTab(0)"
            [class.active]="isFormShown()"
          >
            {{ '::DocumentInformation' | abpLocalization }}
          </button>
        </li>
        <li class="nav-item" role="presentation"
          *ngIf="isGrantedPermissionForSelectingTags || (isView && !isTenantExist())"
        >
          <button
            class="nav-link"
            id="main-tab1"
            type="button"
            role="tab"
            (click)="setActiveTab(1)"
            [class.active]="!isFormShown()"
          >
            {{ '::Tags' | abpLocalization }}
          </button>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent0" [style.display]="isFormShown ? '' : 'none'">
        <div
          class="tab-pane fade"
          id="0"
          role="tabpanel"
          aria-labelledby="main-tab0"
          [class.active]="isFormShown()"
          [class.show]="isFormShown()"
        >

        <div class="form-group mb-3">
          <label class="form-label" for="document-name">{{ '::Name' | abpLocalization }}</label
          ><span *ngIf="!isView"> * </span>
          <input type="text" id="document-name" class="form-control" formControlName="name" [readOnly]="isView" [abpDisabled]="isView"/>
        </div>

        <div class="form-group mb-3">
          <label class="form-label" for="document-code">{{ '::Code' | abpLocalization }}</label
          ><span *ngIf="!isView"> * </span>
          <input type="text" id="document-code" class="form-control" formControlName="code" [readOnly]="isView" [abpDisabled]="isView"/>
        </div>

        <div class="form-group mb-3">
          <label class="form-label" for="document-description">
            {{ '::Description' | abpLocalization }}
          </label>
          <textarea
            id="document-description"
            class="form-control"
            cols="30"
            rows="10"
            formControlName="description"
            [readOnly]="isView" 
            [abpDisabled]="isView"
          ></textarea>
        </div>

        <div class="form-group mb-3">
          <label 
              class="form-label" 
              for="parent-category-name"
          >
              {{ '::Category' | abpLocalization }}
          </label>
          <input
              id="typeahead-focus"
              type="text"
              class="form-control"
              formControlName="category"
              [editable]="false"
              [ngbTypeahead]="searchForCategory"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focus)="focusForCategorySearch$.next($any($event).target.value)"
              (click)="clickForCategorySearch$.next($any($event).target.value)"
              #instance="ngbTypeahead"
              [readOnly]="isView" 
              [abpDisabled]="isView"
          />
        </div>

        <div class="form-group mb-3">
          <label for="test-name" class="form-label">
            {{ '::Test' | abpLocalization }}
          </label>
          <input
            id="typeahead-focus"
            type="text"
            class="form-control"
            formControlName="test"
            [editable]="false"
            [ngbTypeahead]="searchForTest"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            (focus)="focusForTestSearch$.next($any($event).target.value)"
            (click)="clickForTestSearch$.next($any($event).target.value)"
            #instance="ngbTypeahead"
            [readOnly]="isView" 
            [abpDisabled]="isView"
          />
        </div>

        <div *ngIf="currentUserTenantId != null">
          <div class="form-group mb-3" *ngIf="selectedDocument.id || isCurrentUserResponsibleForApproval">
            <label for="task-status" class="form-label">{{ '::Status' | abpLocalization }}</label> <span> *</span>
            <select id="task-status" class="form-select form-control" formControlName="status" [abpDisabled]="!isCurrentUserResponsibleForApproval"> 
                <option value=""></option>
                <option [ngValue]="status.value" *ngFor="let status of documentStatusTypes">
                    {{ '::Enum:DocumentApprovalStatus:' + status.value | abpLocalization }}
                </option>
            </select>
          </div>
        </div>


        <ng-container *ngIf="getTestAttemptFeature()">
            <div *abpPermission="'Tests.Tests.Attempt'">
                <div class="form-group mb-3" *ngIf="isView && selectedDocument.test">
                    <button type="button" (click)="attemptDocumentTest(selectedDocument.testId, selectedDocument.id)" class="btn btn-primary w-100">
                        {{ '::TakeATest' | abpLocalization }}
                    </button>
                </div>
          </div>          
        </ng-container>

        <ng-container *ngIf="freeFieldSelected">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field">{{ '::FreeField' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction">
              {{ '::FreeFieldFillingInstruction' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected1">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-1">{{ '::FreeField1' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field-1" class="form-control" formControlName="freeField1"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-1">
              {{ '::FreeFieldFillingInstruction1' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-1"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction1"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected2">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-2">{{ '::FreeField2' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField2"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-2">
              {{ '::FreeFieldFillingInstruction2' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-2"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction2"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected3">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-3">{{ '::FreeField3' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field-3" class="form-control" formControlName="freeField3"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-3">
              {{ '::FreeFieldFillingInstruction3' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-3"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction3"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected4">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-4">{{ '::FreeField4' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField4"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-4">
              {{ '::FreeFieldFillingInstruction4' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-4"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction4"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected5">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-5">{{ '::FreeField5' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField5"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-5">
              {{ '::FreeFieldFillingInstruction5' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-5"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction5"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected6">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-6">{{ '::FreeField6' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField6"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-6">
              {{ '::FreeFieldFillingInstruction6' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-6"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction6"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected7">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-7">{{ '::FreeField7' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField7"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-7">
              {{ '::FreeFieldFillingInstruction7' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-7"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction7"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected8">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-8">{{ '::FreeField8' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField8"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-8">
              {{ '::FreeFieldFillingInstruction8' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-8"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction8"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected9">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-9">{{ '::FreeField9' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField9"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-9">
              {{ '::FreeFieldFillingInstruction9' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-9"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction9"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected10">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-10">{{ '::FreeField10' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField10"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-10">
              {{ '::FreeFieldFillingInstruction10' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-10"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction10"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected11">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-11">{{ '::FreeField11' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField11"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-11">
              {{ '::FreeFieldFillingInstruction11' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-11"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction11"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected12">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-12">{{ '::FreeField12' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField12"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-12">
              {{ '::FreeFieldFillingInstruction12' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-12"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction12"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected13">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-13">{{ '::FreeField13' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField13"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-13">
              {{ '::FreeFieldFillingInstruction13' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-13"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction13"
            ></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="freeFieldSelected14">
          <div class="form-group mb-3 mt-3">
            <label class="form-label" for="document-free-field-14">{{ '::FreeField14' | abpLocalization }}</label
            ><span> *</span>
            <input type="text" id="document-free-field" class="form-control" formControlName="freeField14"/>
          </div>
  
          <div class="form-group mb-3">
            <label class="form-label" for="document-free-field-filling-instruction-14">
              {{ '::FreeFieldFillingInstruction14' | abpLocalization }}
            </label><span> *</span>
            <textarea
              id="document-free-field-filling-instruction-14"
              class="form-control"
              cols="30"
              rows="3"
              formControlName="freeFieldFillingInstruction14"
            ></textarea>
          </div>
        </ng-container>


        <div class="form-group mb-3" *ngIf="!isView">
          <label class="form-label" for="document-file"> {{ '::FileAttachment' | abpLocalization }} </label><span> * </span>
            <div class="input-group mb-3">
              <input
                #takeInput
                class="form-control"
                type="file"
                formControlName="file"
                id="document-file"
                aria-describedby="document-file"
                aria-label="{{ '::DocumentName' | abpLocalization }}"
                (change)="onFileChanged($event)"
                [accept]="allowedFileTypesFormattedString"
                [abpDisabled]="modalBusy"
              />
              <div
                ngbDropdown
                placement="bottom-right"
                *ngIf="selectedDocument.fileDescriptorId"
                container="body"
              >
                <button
                  class="btn btn-outline-secondary dropdown-toggle download-button"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  ngbDropdownToggle
                >
                  {{ '::Actions' | abpLocalization }}
                </button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem (click)="downloadFile()">
                    {{ '::DownloadCurrentFileVersion' | abpLocalization }}
                  </button>
                </div>
              </div>
            </div>
        </div>        
      
        <div class="form-group mb-3" *ngIf="isView && selectedDocument.fileDescriptorId">
            <label class="form-label" for="document-file"> {{ '::FileAttachment' | abpLocalization }} </label>
            <button (click)="downloadFile()" type="button" role="button" class="btn btn-primary w-100">
                {{ '::DownloadCurrentFileVersion' | abpLocalization }}
            </button>
        </div>

        <div class="form-group mb-3" *ngIf="!isView && this.isGrantedPermissionForSettingProductPageUrl">
          <label class="form-label" for="document-page-link">{{ '::ProductPageUrl' | abpLocalization }}</label>
          <div class="input-group mb-3">
            <span class="input-group-text" *ngIf="ecommerceUrl">{{ ecommerceUrl }}</span>
            <input type="text" id="document-page-link" class="form-control" formControlName="productUrlPath"/>
          </div>
        </div>

        <div 
          class="form-group mb-3"
          *ngIf="isView && selectedDocument.productUrlPath && ecommerceUrl">
          <label class="form-label" for="document-page-link">{{ '::ProductPageUrl' | abpLocalization }}</label>
          <button 
            (click)="goToProductPage()"
            type="button" 
            role="button" 
            class="btn btn-primary w-100"
          >
            {{ '::ProductPageUrlButton' | abpLocalization }}
          </button>
        </div>

        <div class="form-group mb-3" *ngIf="showAvailableUntilField()">
          <label for="available-date">{{ '::AvailableUntilDate' | abpLocalization }} </label>
          <div class="row">
            <div>
              <input 
                id="available-date"
                #datepickerStart="ngbDatepicker"
                class="form-control mt-2"
                name="datepickerStart"
                formControlName="availableUntil"
                ngbDatepicker
                (click)="datepickerStart.toggle()"
                [abpDisabled]="isView"
                [readOnly]="isView"
              >
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isTenantExist() || (isTenantExist() && isView)">
          <label class="form-label" for="document-renewal-period">{{ '::DocumentRenewalPeriod' | abpLocalization }}</label>
          <div class="col">
              <input type="number" id="document-name" class="form-control" formControlName="renewalPeriodNumber" [readOnly]="isView" [abpDisabled]="isView"/>
            </div>
          <div class="col">
            <select id="renewalPeriod" class="form-select form-control" formControlName="renewalPeriod" [readOnly]="isView" [abpDisabled]="isView">
              <option value=""></option>
              <option [ngValue]="renewalPeriod.value" *ngFor="let renewalPeriod of renewalPeriods">
                {{ '::Enum:Document:RenewalPeriod:' + renewalPeriod.value | abpLocalization }}
              </option>
            </select>
          </div>
        </div>

    </div>
  </div>

    <div 
      *ngIf="isGrantedPermissionForSelectingTags || (!isFormShown() && !isTenantExist())"
      class="tab-content" id="myTabContent0">
      <div
        class="tab-pane fade"
        id="1"
        role="tabpanel"
        aria-labelledby="main-tab1"
        [class.active]="!isFormShown()"
        [class.show]="!isFormShown()"
      >
        <div class="form-group mb-3" *ngIf="!isView">
          <label for="document-tag">{{ '::TagForDocumentSelect' | abpLocalization }}</label>
          <select id="document-tag" class="form-select form-control" (change)="onTagSelected($event.target.value)">
            <option value=""></option>
            <option [value]="generatorTag.id" *ngFor="let generatorTag of generatorTags.items">{{ generatorTag.tag }}</option>
          </select>
        </div>

        <ngx-datatable 
          [rows]="tags.items" 
          [count]="tags.totalcount" 
          [limit]="10"
          #width
          default>
          <ngx-datatable-column
              *ngIf="!isView"
              [name]="'::Actions' | abpLocalization"
              [maxWidth]="80"
              [sortable]="false"
          >
              <ng-template let-row="row" ngx-datatable-cell-template>
                  <div container="body" class="d-inline-block">
                  <button
                      class="btn btn-danger btn-sm"
                      aria-haspopup="true"
                      (click)="removeTag(row)"
                      type="button"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                  </div>
              </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
              [name]="'::Tag' | abpLocalization"
              prop="generatorTag"
          ></ngx-datatable-column>
      </ngx-datatable>
      
      </div>
    </div>

    </form>
  </ng-template>

  <ng-template #abpFooter>

    <button 
      type="button" 
      class="btn btn-secondary" 
      abpClose>
      {{ '::Close' | abpLocalization }}
    </button>

    <abp-button 
      iconClass="fa fa-check" 
      (click)="saveDocument()" 
      [disabled]="form.invalid"
      *ngIf="!isView"
    >
      {{
        (isClassToEdit ? '::Edit' : '::Save') | abpLocalization 
      }}
    </abp-button>

  </ng-template>
</abp-modal>

<abp-modal [(visible)]="isCopyModalOpen" [busy]="isLoading">
  <ng-template #abpHeader>
    <h3>{{ '::DocumentCopy' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="copyForm" (ngSubmit)="saveDocumentCopy()">
      <div class="form-group">
        <label for="document-tenant">{{ '::TenantForDocumentCopy' | abpLocalization }}</label><span> *</span>
        <select id="document-tenant" class="form-select form-control" formControlName="tenantId">
          <option value=""></option>
          <option [ngValue]="tenant.id" *ngFor="let tenant of tenants$ | async">{{ tenant.name }}</option>
        </select>
      </div>
    </form>
  </ng-template>
  

  <ng-template #abpFooter>
    <button
      type="button"
      class="btn btn-secondary"
      abpClose
    >
      {{ '::Close' | abpLocalization }}
    </button>

    <abp-button 
      iconClass="fa fa-check" 
      (click)="saveDocumentCopy()" 
      [disabled]="copyForm.invalid"
    >
      {{ '::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="isAssignByReferenceModalOpen" [busy]="isLoading">
  <ng-template #abpHeader>
    <h3>{{ '::AssignAccessByReference' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="assignByReferenceForm">
      <div class="form-group">
        <label for="document-tenant-reference">{{ '::Tenant' | abpLocalization }}</label><span> *</span>
        <select id="document-tenant-reference" class="form-select form-control" formControlName="tenantId">
          <option value=""></option>
          <option [ngValue]="tenant.id" *ngFor="let tenant of tenants$ | async">{{ tenant.name }}</option>
        </select>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button
      type="button"
      class="btn btn-secondary"
      abpClose
    >
      {{ '::Close' | abpLocalization }}
    </button>

    <abp-button 
      iconClass="fa fa-check" 
      (click)="assignDocumentByReference()" 
      [disabled]="assignByReferenceForm.invalid"
    >
      {{ '::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="isMultiSelectModalOpen">

  <ng-template #abpHeader>
    <h3>{{ '::NewTask' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="multiSelectTaskForm" (ngSubmit)="saveMultiSelectTask()">
      <div class="form-group mb-3">
        <label for="task-name" class="form-label">{{ '::Name' | abpLocalization }} </label><span> *</span>
        <input type="text" id="task-name" class="form-control" formControlName="name">
      </div>

      <div class="form-group mb-3">
        <label for="task-description" class="form-label">{{ '::Description' | abpLocalization }}</label>
        <textarea id="task-description" class="form-control" cols="30" rows="4" formControlName="description"></textarea>
      </div>

      <div>
        <div class="form-group mb-3">
          <label class="form-label" for="ticket-users-id">
            {{ '::Users' | abpLocalization }}
          </label><span> *</span>

          <input
            class="form-control" 
            id="ticket-users-id"
            type="search"
            formControlName="assignedUserId"
            [editable]="false"
            [resultFormatter]="tenantFormatter"
            [inputFormatter]="tenantFormatter"
            [ngbTypeahead]="searchTenantForTicketUsers"
            (focus)="focusForTicketTenantUser$.next($any($event).target.value)"
            (click)="clickForTicketTenantUser$.next($any($event).target.value)"
            (ngModelChange)="selectedNewTaskUsers($event)"
            #instance="ngbTypeahead"
            [abpDisabled]="isTicketDocumentApprovalSelected"
          />
        </div>

        <button 
          type="button" 
          class="btn btn-primary"
          (click)="selectAllTenantUsers()"
          [disabled]="isTicketDocumentApprovalSelected"
        >
          {{ '::SelectAll' | abpLocalization }}
        </button>

        <ngx-datatable 
          [rows]="selectedUsers.items" 
          [count]="selectedUsers.totalCount" 
          [limit]="5"
          #width
          default
        >

          <ngx-datatable-column
            [name]="'::Actions' | abpLocalization"
            [maxWidth]="80"
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div container="body" class="d-inline-block">
                <button
                  class="btn btn-danger btn-sm"
                  aria-haspopup="true"
                  (click)="removeAssignedUser(row)"
                  type="button"
                  [disabled]="isTicketDocumentApprovalSelected"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="'::UserName' | abpLocalization"
            prop="userName"
          >
          </ngx-datatable-column>

        </ngx-datatable>

      </div>

      <div class="form-group">
        <label for="task-start-time">{{ '::StartDateTime' | abpLocalization }} </label><span> *</span>
        <div class="row">
          <div class="col-8">
            <input 
              id="task-start-time"
              #datepickerStart="ngbDatepicker"
              class="form-control mt-2"
              name="datepickerStart"
              formControlName="startDate"
              ngbDatepicker
              (click)="datepickerStart.toggle()"
            >
          </div>
          <div class="col-4 picker">
            <ngb-timepicker
              formControlName="startTime"
            >
            </ngb-timepicker>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="task-end-time">{{ '::EndDateTime' | abpLocalization }}</label> <span> *</span>
        <div class="row">
          <div class="col-8">
            <input
              id="task-end-time"
              #datepickerEnd="ngbDatepicker"
              class="form-control mt-2"
              name="datepickerEnd"
              formControlName="endDate"
              ngbDatepicker
              (click)="datepickerEnd.toggle()"
            >
          </div>
          <div class="col-4 picker">
            <ngb-timepicker
              formControlName="endTime"
            >
            </ngb-timepicker>
          </div>
        </div>
    </div>

    <div class="form-group mb-3">
      <label for="task-kind" class="form-label">{{ '::TaskKind' | abpLocalization }}</label>
      <select id="task-kind" class="form-select form-control" formControlName="ticketKind">
        <option [ngValue]="null"></option>
        <option [ngValue]="kind.value" *ngFor="let kind of ticketKinds">
          {{ '::Enum:TaskKind:' + kind.value | abpLocalization }}
        </option>
      </select>
    </div>

    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button 
      type="button" 
      class="btn btn-secondary" 
      abpClose>
      {{ '::Close' | abpLocalization }}
    </button>

    <abp-button 
      iconClass="fa fa-check" 
      (click)="saveMultiSelectTask()" 
      [disabled]="multiSelectTaskForm.invalid"
    >
      {{
        '::Save' | abpLocalization 
      }}
    </abp-button>
  </ng-template>

</abp-modal>

<abp-modal [(visible)]="isUploadModalOpen">
  <ng-template #abpHeader>
    <h3>{{ '::DocumentsUpload' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="uploadForm" (ngSubmit)="uploadDocuments()">
      <div class="form-group">
        <label for="documents-upload-tenant">{{ '::TenantForDocumentsUpload' | abpLocalization }}</label><span> *</span>
        <select id="documents-upload-tenant" class="form-select form-control" formControlName="tenantId">
          <option value=""></option>
          <option [ngValue]="tenant.id" *ngFor="let tenant of tenants$ | async">{{ tenant.name }}</option>
        </select>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button 
      type="button" 
      class="btn btn-secondary" 
      abpClose>
      {{ '::Close' | abpLocalization }}
    </button>

    <abp-button 
      iconClass="fa fa-check" 
      (click)="uploadDocuments()"
      [disabled]="uploadForm.invalid"
    >
      {{
        '::Upload' | abpLocalization 
      }}
    </abp-button>
  </ng-template>
</abp-modal>
