<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title" *ngIf="courseDto">
            {{ courseDto.name }}
        </h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/"><i class="fa fa-home"></i> </a>
            </li>
            <li class="breadcrumb-item">
                <a routerLink="/courses">{{ '::Menu:Course' | abpLocalization }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ "::ViewCourse"  | abpLocalization }}
            </li>
        </ol>
    </div>
</div>
<div id="wrapper">
    <div class="row">
        <div class="col-lg-9 col-sm-12">
            <app-spinner [isLoading]="isLoading"></app-spinner>
            <div class="card d-flex"> 
                    <app-courses-video-player
                        [class]="selectedClassDto"
                        [fileStreamSource]="fileStreamSource"
                        *ngIf="isVideo && !isLoading">
                    </app-courses-video-player>
                        
                    <app-courses-audio-player
                        [class]="selectedClassDto"
                        [fileStreamSource]="fileStreamSource"
                        *ngIf="isAudio && !isLoading">
                    </app-courses-audio-player>

                    <app-courses-photo-viewer
                        [fileStreamSource]="fileStreamSource"
                        [class]="selectedClassDto"
                        *ngIf="isPhoto && !isLoading">
                    </app-courses-photo-viewer>

                    <app-courses-file-viewer
                        [class]="selectedClassDto"
                        *ngIf="isDocument && !isLoading">
                    </app-courses-file-viewer>

                    <app-courses-class-text
                        [class]="selectedClassDto"
                        *ngIf="isText && !isLoading">
                    </app-courses-class-text>
            
            </div>

            <div>
                <div class="card bg-info">
                    <div class="card-body">
                        <p class="card-text">{{ '::Course:Class:VideoPlayer:Info:Message' | abpLocalization }}</p>
                    </div>
                </div>
            </div>

            <div class="card">
                <app-courses-class-tabs
                    *ngIf="selectedClassDto"
                    [courseHours]="courseHours"
                    [courseMinutes]="courseMinutes"
                    [course]="courseDto">
                </app-courses-class-tabs>
            </div>
        </div>
        <div class="col-lg-3 col-sm-12">

            <app-courses-task-list *ngIf="isTaskListShown()" (updatedTask)="onUpdatedTask($event)" [tasks]="tasks"></app-courses-task-list>

            <div class="card overflow-auto" style="height: 760px;">
                <div class="card-title">
                    <h3 class="card-title m-2 mx-3">
                        {{ '::CourseContent' | abpLocalization }}
                    </h3>
                </div>
                <ul class="list-group" *ngIf="courseDto">
                    <li 
                        class="list-group-item list-group-item-action"
                        role="button"
                        *ngFor="let class of courseDto.classes; let i = index"          
                        (click)="selectedClassDto.id != class.id ? selectedClass(class.id) : ''" 
                        [ngStyle]="{'backgroundColor': selectedClassDto.id == class.id ? '#ECECEC': ''}">
                        {{ i+1 + ". " }}{{ class.name }}
                        <div class="w-100">
                            <span 
                                *ngIf="class.fileDescriptorId == null"
                                class="fa fa-file-text px-1">
                            </span> 

                            <span 
                                *ngIf="class.fileDescriptor != null &&
                                getFileType(class.fileDescriptor) == 'video'"
                                class="fa fa-youtube-play px-1">
                            </span> 

                            <span 
                                *ngIf="class.fileDescriptor != null &&
                                getFileType(class.fileDescriptor) == 'image'"
                                class="fas fa-file-image px-1">
                            </span> 

                            <span 
                                *ngIf="class.fileDescriptor != null &&
                                getFileType(class.fileDescriptor) == 'audio'"
                                class="fas fa-volume-up px-1">
                            </span> 

                            <span 
                                *ngIf="class.fileDescriptor != null &&
                                getFileType(class.fileDescriptor) == 'application'"
                                class="fa fa-file px-1">
                            </span> 
                                {{ class.length }} min.
                        </div>
                    </li>
                    <ng-container *ngIf="getTestAttemptFeature()">
                        <div *abpPermission="'Tests.Tests.Attempt'">
                            <li 
                                *ngIf="this.courseDto.test"
                                class="list-group-item list-group-item-action"
                                role="button"
                                (click)="attemptCourseTest(this.courseDto.testId)"
                                >
                                <span 
                                    class="fa fa-check px-1">
                                </span> 
                                {{ '::Test' | abpLocalization }}
                                <div class="w-100 px-1">
                                    {{ this.courseDto.test.name }}
                                </div>                            
                            </li>
                        </div>                     
                    </ng-container>

                </ul>
            </div>
        </div>
    <div>
</div>
