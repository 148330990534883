import { TenantCourseService } from './../../proxy/tenant-courses/tenant-course.service';
import { ConfigStateService, ListResultDto, ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassDto } from '@proxy/classes';
import { CourseClassesService, CourseDto, CoursesService } from '@proxy/courses';
import { TestAttemptEntity } from '@proxy/test-attempts';
import { FileDescriptorDto } from '@volo/abp.ng.file-management/proxy';
import { fileApis } from 'src/environments/environment';
import { FileType } from './fileType';
import { TicketDto } from '@proxy/tickets';
import { mergeMap } from 'rxjs/operators';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-courses-view',
  templateUrl: './courses-view.component.html',
  styleUrls: ['./courses-view.component.scss'],
  providers: [ListService]
})
export class CoursesViewComponent implements OnInit {
  selectedClassDto: ClassDto;
  classes = { items: [], totalCount: 0 } as PagedResultDto<ClassDto>;
  tasks: ListResultDto<TicketDto> = { items: [] } as ListResultDto<TicketDto>;
  fileStreamSource: string;
  courseDto: CourseDto;
  isLoading: boolean = false;
  isTicketsLoading: boolean = false;
  courseLength: number = 0;
  isVideo: boolean;
  isAudio: boolean;
  isPhoto: boolean
  isText: boolean;
  isDocument: boolean;
  courseHours: number;
  courseMinutes: number;
  tenantHostCourse: string;
  DOWNLOAD_CLASS_ENDPOINT = fileApis.downloadClassApi;
  VIEW_CLASS_FILE_STREAM_ENDPOINT = fileApis.viewClassFileStreamApi;

  activeCourseService : TenantCourseService | CoursesService;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private tenantCourseService: TenantCourseService,
    private router: Router,
    private config: ConfigStateService,
    private classesService: CourseClassesService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private confirmation: ConfirmationService,
    public readonly list: ListService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.tenantHostCourse = params['id'];
      if(this.config.getOne("currentUser").tenantId == null){
        this.activeCourseService = this.coursesService;
        this.getCourseData(this.tenantHostCourse);
      } else {
        this.activeCourseService = this.tenantCourseService;
        this.getCourseData(this.tenantHostCourse);
        this.getCourseTickets();
      }
    });
  }


  getCourseData(id: string){
    this.activeCourseService.get(id).subscribe(response => {
      this.courseDto = response;

      this.courseDto.classes.reduce((_, a) => this.courseLength += a.length, 0);
      if(this.courseDto.length > this.courseLength)
        this.courseLength = this.courseDto.length;

      this.courseHours = Math.floor(this.courseLength / 60);
      this.courseMinutes = this.courseLength - (this.courseHours * 60);
      
      if (this.courseDto.classes.length == 0) {
        this.confirmation.warn("::Course:Message:Classes:Error", "::Warning");
      } else {
        this.selectedClass(this.courseDto.classes[0].id)    
      }
    });
  }

  getCourseTickets(){
    this.isTicketsLoading = true;
    this.route.params.pipe(
        mergeMap((params) => 
          this.tenantCourseService.getTenantCourseTicketsByCourseId(params['id'])
        )).subscribe((response) => {
          this.tasks = response;
          this.isTicketsLoading = false;
          this.isTaskListShown();
        }
    )
  }

  isTaskListShown(){
    if(!this.isTicketsLoading && this.tasks.items.length != 0)
      return true;
    return false;
  }

  onUpdatedTask(taskId: string){
    this.tenantCourseService.updateCourseTicketStatusByTicketId(taskId).subscribe({
      complete: () => {
        this.tasks.items.forEach((element, index)=> {
          if(element.id == taskId){
            this.tasks.items.splice(index, 1);
          }
        });
        this.toaster.success(
          this.localizationService.instant("::Course:Ticket:UpdateStatus:Success")
        );
      },
      error: () =>{
        this.toaster.error(
          this.localizationService.instant("::Course:Ticket:UpdateStatus:Error:00001")
        );
      }
    })
  }

  selectedClass(classId: string) {
    this.selectedClassDto = this.courseDto.classes.find(classDto => classDto.id == classId);

    this.classesService.getView(classId, this.tenantHostCourse).subscribe(); //to register that this class was viewed

    this.isAudio = false;
    this.isDocument = false;
    this.isText = false;
    this.isPhoto = false;
    this.isVideo = false;    

    if(!this.selectedClassDto.fileDescriptorId){
      this.isText = true;
    }
    else {
      this.isLoading = true;
      this.fileStreamSource = this.DOWNLOAD_CLASS_ENDPOINT
        .replace('{classId}', classId)
        .replace('{accessToken}', localStorage.getItem('access_token'));

      this.isLoading = false;

      switch(this.getFileType(this.selectedClassDto.fileDescriptor)){
        case FileType.video: {
          this.isVideo = false;
          
          this.fileStreamSource = this.VIEW_CLASS_FILE_STREAM_ENDPOINT
            .replace('{classId}', classId)
            .replace('{accessToken}', localStorage.getItem('access_token'));

          this.isVideo = true;
          break;
        }
        case FileType.audio: {
          this.isAudio = false;

          this.fileStreamSource = this.VIEW_CLASS_FILE_STREAM_ENDPOINT
            .replace('{classId}', classId)
            .replace('{accessToken}', localStorage.getItem('access_token'));

          this.isAudio = true;
          break;
        }
        case FileType.image: {
          this.isPhoto = true;
          break;
        }
        default: {
          this.isDocument = true;
          break;
        }
      }
    }
  }

  attemptCourseTest(testId: string){
    this.router.navigate(["test/attempt"], {queryParams: {
      id: testId,
      entity: TestAttemptEntity.Course,
      pointer: this.tenantHostCourse
    }});
  }

  getFileType(fileDescriptor: FileDescriptorDto): string{
    return fileDescriptor.mimeType.split('/')[0];
  }

  getTestAttemptFeature(): boolean {
    return this.config.getFeature("Test.Attempt") == "true";
  }

}
