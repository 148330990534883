import { ConfigStateService, ListService, LocalizationService, PagedResultDto, PermissionService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CalendarOptions } from '@fullcalendar/core';
import { TicketDto, TicketEducationKind, ticketEducationKindOptions, TicketKind, ticketKindOptions, TicketsService, TicketStatus, ticketStatusOptions } from '@proxy/tickets';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CalendarEvent } from './event';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { TenantUserLookupDto } from '@proxy/enova-users-lookup';
import { BaseCourseDto, TenantCourseWithApprovalDataDto } from '@proxy/courses';
import { BaseDocumentDto, DocumentStatus, TenantDocumentWithApprovalDataDto } from '@proxy/documents';
import { TenantDocumentLookupService } from '@proxy/tenant-documents';
import { TenantCourseLookupService } from '@proxy/tenant-courses';
import dayGridPlan from '@fullcalendar/daygrid';
import dayGridPlugin from '@fullcalendar/daygrid'
import { DocumentApprovalDto, DocumentApprovalSettingsService } from '@proxy/documents-approval';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnovaOrganizationUnitLookupService } from '@proxy/organization-units';
import { LookupDto, LookupRequestDto } from '@proxy/shared';
import { EnovaOrganizationUnitsService } from '@proxy/enova-organization-units';

@Component({
  selector: 'app-tickets-calendar',
  templateUrl: './tickets-calendar.component.html',
  styleUrls: ['./tickets-calendar.component.scss'],
  providers: [ListService, DatePipe,
    {
      provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    }
  ]
})
export class TicketsCalendarComponent implements OnInit {
  task = { items: [], totalCount: 0 } as PagedResultDto<TicketDto>;

  selectedUsers = {
    items: [],
    totalCount: 0
  } as PagedResultDto<TenantUserLookupDto>;

  filteredOrganizationUnits = {
    items: [],
    totalCount: 0
  } as PagedResultDto<LookupDto<string>>

  isModalOpen = false;
  createUpdateTicketForm: UntypedFormGroup;
  events: CalendarEvent[] = [];
  selectedTicket = {} as TicketDto;
  tenantUsers$: Observable<TenantUserLookupDto[]>;
  courses$: Observable<BaseCourseDto[]>;
  documents$: Observable<BaseDocumentDto[]>;
  statusTypes = ticketStatusOptions;
  ticketKinds = ticketKindOptions;
  ticketEducationKinds = ticketEducationKindOptions;
  updatePermission: boolean;
  deletePermission: boolean;
  isView: boolean = false;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridWeek',
    plugins: [dayGridPlugin, dayGridPlan],
  };
  courseLookup: BaseCourseDto[] = [];
  tenantUsersLookup: TenantUserLookupDto[] = [];
  documentLookup: TenantDocumentWithApprovalDataDto[] = [];
  courseWithApprovalDataLookup: TenantCourseWithApprovalDataDto[] = [];
  focusForTicketDocument$ = new Subject<string>();
  clickForTicketDocument$ = new Subject<string>();
  focusForTicketCourse$ = new Subject<string>();
  clickForTicketCourse$ = new Subject<string>();
  focusForTicketDocumentWithApprovalData$ = new Subject<string>();
  clickForTicketDocumentWithApprovalData$ = new Subject<string>();
  focusForTicketCourseWithApprovalData$ = new Subject<string>();
  clickForTicketCourseWithApprovalData$ = new Subject<string>();
  focusForTicketTenant$ = new Subject<string>();
  clickForTicketTenant$ = new Subject<string>();

  isTicketEducationKindShown: boolean = false;
  isDocumentShown: boolean = null;
  isDocumentShownForApproval: boolean = null;
  isTicketEducationKindSelected: boolean = false;
  isTicketDocumentApprovalSelected: boolean = false;
  isTicketEdit: boolean = false;
  selectFromUsers: boolean = false;
  selectFromOrganizationUnit: boolean = false;

  responsibleUserForApproval: DocumentApprovalDto;
  currentUserId: string;

  today: Date = new Date();
  weekFromToday: Date = new Date();

  documentFormatter = (document: TenantDocumentWithApprovalDataDto) => document.name;
  courseFormatter = (course: BaseCourseDto) => course.name;
  tenantFormatter = (tenant: TenantUserLookupDto) => tenant.userName;
  courseForApprovalFormatter = (course: TenantCourseWithApprovalDataDto) => course.name;

  ShowFilter = true;
  limitSelection = false;
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  selectedItemsForCreate = [];

  constructor(
    private taskService: TicketsService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    private localizationService: LocalizationService,
    private toaster: ToasterService,
    private confirmation: ConfirmationService,
    private permissionService: PermissionService,
    private tenantDocumentLookupService: TenantDocumentLookupService,
    private tenantCourseLookupService: TenantCourseLookupService,
    private documentApprovalSettingsService: DocumentApprovalSettingsService,
    private config: ConfigStateService,
    private enovaOrganizationUnitLookupService: EnovaOrganizationUnitLookupService,
    private enovaOrganizationUnitService: EnovaOrganizationUnitsService,
    public readonly list: ListService,
    public readonly orgnUnitlist: ListService) 
  {
    this.tenantUsers$ = taskService.getTenantUserLookup().pipe(map(r => r.items));
    this.courses$ = tenantCourseLookupService.getTenantCourseLookup().pipe(map(r => r.items));
    this.documents$ = tenantDocumentLookupService.getTenantDocumentLookup().pipe(map(r => r.items));
  }

  searchDocumentForTicket: OperatorFunction<string, readonly BaseDocumentDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketDocument$, this.clickForTicketDocument$).pipe(
      map(term => (term === '' ? this.documentLookup
        : this.documentLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchCourseForTicket: OperatorFunction<string, readonly BaseCourseDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketCourse$, this.clickForTicketCourse$).pipe(
      map(term => (term === '' ? this.courseLookup
        : this.courseLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchForNotApprovedDocumentsForTicket: OperatorFunction<string, readonly TenantDocumentWithApprovalDataDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketDocumentWithApprovalData$, this.clickForTicketDocumentWithApprovalData$).pipe(
      map(term => (term === '' ? this.documentLookup.filter(x => x.status == DocumentStatus.NotApproved)
        : this.documentLookup.filter(v => (v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) && v.status == DocumentStatus.NotApproved) )
      )
    );
  };

  searchTenantForTicket: OperatorFunction<string, readonly TenantUserLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  
    return merge(debouncedText$, this.focusForTicketTenant$, this.clickForTicketTenant$).pipe(
      map(term => (term === '' ? this.tenantUsersLookup
        : this.tenantUsersLookup.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchForNotApproveCoursesForTicket: OperatorFunction<string, readonly TenantCourseWithApprovalDataDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketCourseWithApprovalData$, this.clickForTicketCourseWithApprovalData$).pipe(
      map(term => (term === '' ? this.courseWithApprovalDataLookup.filter(x => x.status == DocumentStatus.NotApproved)
        : this.courseWithApprovalDataLookup.filter(v => (v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) && v.status == DocumentStatus.NotApproved) )
      )
    );
  };

  ngOnInit(): void {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;

    this.currentUserId = this.config.getOne("currentUser").id;

    this.documentApprovalSettingsService.getDocumentApprovalInformation(true).subscribe((response) => {
      this.responsibleUserForApproval = response;
    });

    this.updatePermission = this.permissionService.getGrantedPolicy('Tickets.Tickets.Edit');

    this.deletePermission = this.permissionService.getGrantedPolicy('Tickets.Tickets.Delete')

    if(this.updatePermission || this.permissionService.getGrantedPolicy('Tickets.Tickets.Create')){
      this.tenantDocumentLookupService.getTenantDocumentWithApprovalDataLookup().subscribe((response) => {
        this.documentLookup = response.items;
      });

      this.tenantCourseLookupService.getTenantCourseWithApprovalDataLookup().subscribe((response) => {
        this.courseWithApprovalDataLookup = response.items;
      });
  
      this.tenantCourseLookupService.getTenantCourseLookup().subscribe((response) => {
        this.courseLookup = response.items;
      });

      this.taskService.getTenantUserLookup().subscribe((response) => {
        this.tenantUsersLookup = response.items;
      });
    }

    const taskStreamCreator = (query) => this.taskService.getList({
      ...query,
      maxResultCount: 1000
    });

    this.list.hookToQuery(taskStreamCreator).subscribe((response) => {
      this.task = response;
      this.events = [];
      this.task.items.forEach(element => {
        this.events.push({
          id: element.id,
          title: element.name,
          start: element.startDateTime,
          end: element.endDateTime,
          allDay: false,
          color: element.status == 0 ? '#808080' : (element.status == 1 ? '#0096FF' : '#378006')
        });
      });
      this.addCalendarOptions();
    });

    this.getOrganizationUnits();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter,
      defaultOpen: false,
      enableCheckAll: false,
      noDataAvailablePlaceholderText: this.localizationService.instant("::NoDataAvailable"),
      searchPlaceholderText: this.localizationService.instant("::Search")
    };
  }

  organizationUnitFilterChange(event: any) {
    this.fetchOrganizationUnits({ filter: event } as LookupRequestDto);
  }
  
  getOrganizationUnits() {
    this.fetchOrganizationUnits({} as LookupRequestDto);
  }

  private fetchOrganizationUnits(requestDto: LookupRequestDto) {
    const orgUnitStreamCreator = (query) => {
      const updatedQuery = query.filter !== undefined ? { ...query } : { ...query, filter: requestDto.filter };
      return this.enovaOrganizationUnitLookupService.getOrganizationUnitLookup(updatedQuery);
    };
  
    this.orgnUnitlist.hookToQuery(orgUnitStreamCreator).subscribe((response) => {
      if (response && response.items && response.items.length > 0) {
        this.dropdownList = response.items.map(item => item.displayName);
      } else {
        this.dropdownList = [{
          item_id: 0,
          item_text: this.localizationService.instant("::NoDataAvailable"),
          isDisabled: true
        }];
      }
      
      if (response) {
        this.filteredOrganizationUnits = response;
      } else {
        this.filteredOrganizationUnits = {
          items: [],
          totalCount: 0
        };
      }
    });
  }

  selectUsersFromSelectedOrganizationUnit() {
    var data = this.selectedItemsForCreate.length > 0 ? this.selectedItemsForCreate.map(data => data.id) : null;

    if (!data) {
      this.toaster.warn(
        this.localizationService.instant("::Organization:Unit:Selection:IsEmpty")
      );

      return;
    }

    this.enovaOrganizationUnitService.getUsers(data).subscribe((result) => {
      this.addUsersToList(result.items);
    });
    
  }

  addUsersToList(selectedUsers: TenantUserLookupDto[]) {
    if (selectedUsers) {
      if (this.selectedUsers.totalCount > 0) {
        selectedUsers.forEach(element => {
          var isValuePresent = this.selectedUsers.items.find(x => x.userName == element.userName);

          if (!isValuePresent) {
            this.selectedUsers.items.push({userName: element.userName, id: element.id});

            this.selectedUsers.items = [...this.selectedUsers.items]
            this.selectedUsers.totalCount++;
          }
        });
      } else {
        this.selectedUsers.items = selectedUsers;
        this.selectedUsers.totalCount = selectedUsers.length;
  
        this.selectedUsers.items = [...this.selectedUsers.items];
      }

      this.checkIfSelectedUsers();
    }
  }

  onSelectionFromValueChange(event: any) {
    this.selectedItemsForCreate = [];
    switch(event) {
      case "true":
        this.selectFromUsers = true;
        this.selectFromOrganizationUnit = false;
        break;
      case "false":
        this.selectFromUsers = false;
        this.selectFromOrganizationUnit = true;
        break;
      default: 
        this.selectFromUsers = false;
        this.selectFromOrganizationUnit = false;
        break;
    }
  }

  onOrganizationUnitCreateDeSelect(item: any) {
    var data = this.selectedItemsForCreate.find(x => x.displayName === item);

    var indexOfData = this.selectedItemsForCreate.indexOf(data);
    this.selectedItemsForCreate.splice(indexOfData, 1);
  }

  onOrganizationUnitCreateSelect(item: any) {
    var data = this.filteredOrganizationUnits.items.find(x => x.displayName === item);
    this.selectedItemsForCreate.push({name: data.displayName, id: data.id});
  }

  addCalendarOptions(){
    this.calendarOptions = {   
      firstDay: 1,
      buttonText:{
        month: this.localizationService.instant('::Ticket:Calendar:Month'),
        week: this.localizationService.instant('::Ticket:Calendar:Week'),
        today: this.localizationService.instant('::Ticket:Calendar:Today')
      },
      customButtons: {
        createNewTaskButton: {
          text: this.localizationService.instant('::NewTask'),
          click: () => {
            this.selectedUsers.items = [];
            this.selectedUsers.totalCount = 0;
            this.selectedTicket = {} as TicketDto;
            this.isTicketEdit = false;
            this.isTicketEducationKindShown = false;
            this.selectFromUsers = false;
            this.selectFromOrganizationUnit = false;
            this.weekFromToday.setDate(this.today.getDate() + 7);
            this.buildFormForCreate();
            this.isDocumentForApprovalSelectShown();
            this.isView = false;
            this.isModalOpen = true;
          }
        },
      },   
      events: this.events,
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: true
      },
      headerToolbar: {
        start: 'title', 
        center: '',
        end: this.permissionService.getGrantedPolicy('Tickets.Tickets.Create')
          ? 'createNewTaskButton dayGridMonth dayGridWeek today prev,next' 
          : 'dayGridMonth dayGridWeek today prev,next'
      },
      eventClick: (info) => {
        this.selectedUsers.items = [];
        this.selectedUsers.totalCount = 0;

        this.isDocumentShown = null;
        this.isDocumentShownForApproval = null;
        this.isTicketEdit = true;

        this.taskService.get(info.event.id).subscribe((ticket) => {
          this.selectedTicket = ticket;

          this.isTicketEducationKindShown = ticket.ticketKind == TicketKind.Education ? true : false;
          
          if(this.isTicketEducationKindShown) {
            this.isDocumentShown = ticket.document != null ? true : (ticket.course != null ? false : null);
          }

          if(this.selectedTicket.ticketKind == TicketKind.DocumentApproval) {
            if(ticket.document != null) {
              this.isDocumentShownForApproval = true;
              this.selectedTicket.ticketEducationKind = TicketEducationKind.Document;
            }
    
            if(this.selectedTicket.course != null) {
              this.isDocumentShownForApproval = false;
              this.selectedTicket.ticketEducationKind = TicketEducationKind.Course;
            }
    
            this.selectedUsers.items = [{userName: this.selectedTicket.assignedUserName, id: this.selectedTicket.assignedUserId}]
            this.selectedUsers.totalCount = 1;
      
            this.isTicketDocumentApprovalSelected = true;
            this.isTicketEducationKindShown = true;
          }

          this.buildFormForCreate();

          //if current user has only view permissions
          if (!this.permissionService.getGrantedPolicy('Tickets.Tickets.Edit') && 
              !this.permissionService.getGrantedPolicy('Tickets.Tickets.Create') && 
              this.permissionService.getGrantedPolicy('Tickets.Tickets.View')) {
              this.createUpdateTicketForm.controls["assignedUserId"].disable();
              this.createUpdateTicketForm.controls["assignedUserId"].updateValueAndValidity();
          }

          if(ticket.ticketEducationKind != null) {
            this.getSelectedTaskEducationKind(ticket.ticketEducationKind);
          }

          this.weekFromToday.setDate(this.today.getDate() + 7);
          if(this.isDocumentShown == true){
            this.createUpdateTicketForm.controls["document"].addValidators(Validators.required);
            this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
          }
          if(this.isDocumentShown == false){
            this.createUpdateTicketForm.controls["course"].addValidators(Validators.required);
            this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
          }
          this.isView = !this.permissionService.getGrantedPolicy('Tickets.Tickets.Edit');
          this.isModalOpen = true;
          
        })
      }
    };
  }

  getSelectedTaskKind(kind) {
    this.createUpdateTicketForm.controls["document"].setValue(null);
    this.createUpdateTicketForm.controls["course"].setValue(null);
    this.createUpdateTicketForm.controls["ticketEducationKind"].setValue(null);

    if(kind == TicketKind.Education) {
      this.isTicketEducationKindShown = true;
      this.isDocumentShown = null;
      this.isDocumentShownForApproval = null;
      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);

      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators([Validators.required]);
    } 
    else if(kind == TicketKind.Renewal) { 
      this.isTicketEducationKindShown = false;      
      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);

      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators(null);
    }
    else if(kind == TicketKind.DocumentApproval) {    
      if(this.responsibleUserForApproval == null) {
        this.createUpdateTicketForm.controls["ticketKind"].setValue(null);

        this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
        this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
        this.createUpdateTicketForm.controls["ticketKind"].updateValueAndValidity();

        this.toaster.warn(
          this.localizationService.instant("::TicketForApproval:Error:00001")
        );

        return;
      }

      this.isTicketEducationKindShown = true;
      this.isDocumentShown = null;
      this.isDocumentShownForApproval = null;

      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);
      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators([Validators.required]);
      this.createUpdateTicketForm.controls["taskStatus"].setValue(TicketStatus.New);
      this.createUpdateTicketForm.controls["taskStatus"].updateValueAndValidity();
    }
    else {
      this.isTicketEducationKindShown = false;
      this.isDocumentShown = null;
      this.isDocumentShownForApproval = null;

      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);
      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators(null);
    }

    this.isDocumentForApprovalSelectShown();

    this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
    this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
    this.createUpdateTicketForm.controls["ticketEducationKind"].updateValueAndValidity();
  }

  isDocumentForApprovalSelectShown() {
    var value = this.createUpdateTicketForm.controls["ticketKind"].value;

    if (value == TicketKind.DocumentApproval) {
      this.createUpdateTicketForm.controls["taskStatus"].setValue(this.selectedTicket?.status != null ? this.selectedTicket.status : TicketStatus.New);
      this.createUpdateTicketForm.controls["assignedUserId"].setValue(this.responsibleUserForApproval.selectedUser.id);

      this.selectedUsers.items = [{userName: this.responsibleUserForApproval?.selectedUser?.userName, id: this.responsibleUserForApproval?.selectedUser?.id}]
      this.selectedUsers.totalCount = 1;

      this.isTicketDocumentApprovalSelected = true;
      return true;
    }

    this.isTicketDocumentApprovalSelected = false;

    return false;
  }

  isCourseApproval() {
    if(this.createUpdateTicketForm.controls["course"].hasValidator(Validators.required) && this.isDocumentShownForApproval == false)
      return true;
    return false;
  }

  isDocumentApproval() {
    if(this.createUpdateTicketForm.controls["document"].hasValidator(Validators.required)  && this.isDocumentShownForApproval == true)
      return true;
    return false;
  }

  disableTicketStatusField(): boolean {
    
    if(!this.isTicketDocumentApprovalSelected){
      return false;
    }

    if(this.currentUserId == this.responsibleUserForApproval?.selectedUser.id){
      if( this.isTicketEdit && this.isTicketDocumentApprovalSelected ) 
      {
        return false;
      }
    }

    return true;
  }

  getSelectedTaskEducationKind(kind) {
    var ticketKind = this.createUpdateTicketForm.get('ticketKind').value;

    if(ticketKind == TicketKind.Education) {
      if(kind == TicketEducationKind.Course){
        this.isDocumentShown = false;
        this.createUpdateTicketForm.controls["course"].setValidators(Validators.required);
        this.createUpdateTicketForm.controls["document"].setValue(null);
        this.createUpdateTicketForm.controls["document"].setValidators(null);
      }
  
      if(kind == TicketEducationKind.Document){
        this.isDocumentShown = true;      
        this.createUpdateTicketForm.controls["document"].setValidators(Validators.required);
        this.createUpdateTicketForm.controls["course"].setValue(null);
        this.createUpdateTicketForm.controls["course"].setValidators(null);
  
      }

      if(kind === "") {
        this.isDocumentShown = null;
      } 
    }

    if(ticketKind == TicketKind.DocumentApproval) {
      if (kind == TicketEducationKind.Course) {
        this.isDocumentShownForApproval = false;
        this.createUpdateTicketForm.controls["course"].setValidators(Validators.required);
        this.createUpdateTicketForm.controls["document"].setValue(null);
        this.createUpdateTicketForm.controls["document"].setValidators(null);
      }

      if (kind == TicketEducationKind.Document) {
        this.isDocumentShownForApproval = true;      
        this.createUpdateTicketForm.controls["document"].setValidators(Validators.required);
        this.createUpdateTicketForm.controls["course"].setValue(null);
        this.createUpdateTicketForm.controls["course"].setValidators(null);
      }
  
      if (kind === "") {
        this.isDocumentShownForApproval = null;
      }   
    }

    this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
    this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
  }

  isDocumentSelectShown() {
    var value = this.createUpdateTicketForm.controls["ticketKind"].value;
    
    if (!(value in TicketKind) || value == TicketKind.DocumentApproval) {
      return false;
    }
  
    if(this.isTicketEducationKindShown == false){
      return true;
    } else{
        if(this.isDocumentShown == true)
          return true;
        else 
          return false; 
    }
  }

  isCourseSelectShown() {
    var value = this.createUpdateTicketForm.controls["ticketKind"].value;

    if(!(value in TicketKind) || value == TicketKind.DocumentApproval){
      return false;
    } 
    
    if(this.isTicketEducationKindShown == false){
      return true;
    } 
    else {
        if(this.isDocumentShown == false)
          return true;
        else 
          return false;
    }
  }

  courseHasRequiredValidator(){
    if(this.createUpdateTicketForm.controls["course"].hasValidator(Validators.required))
      return true;
    return false;
  }

  documentHasRequiredValidator(){
    if(this.createUpdateTicketForm.controls["document"].hasValidator(Validators.required))
      return true;
    return false;
  }

  buildFormForCreate() {
    this.isView = false;
    this.createUpdateTicketForm = this.formBuilder.group({
      name: [this.selectedTicket.name || this.localizationService.instant("::Ticket:Default:Name"), Validators.required],
      description: [this.selectedTicket.description || ''],
      assignedUserId: [this.selectedTicket.assignedUserId || null, Validators.required],
      taskStatus: [this.selectedTicket.status != null ? this.selectedTicket.status : TicketStatus.New , Validators.required],
      ticketKind: [this.selectedTicket.ticketKind != null ? this.selectedTicket.ticketKind : null, Validators.required],
      ticketEducationKind: [this.selectedTicket.ticketEducationKind != null ? this.selectedTicket.ticketEducationKind : null],
      course: [this.selectedTicket.course || null],
      document: [this.selectedTicket.document || null],
      startDate: [this.selectedTicket.startDateTime 
        ? new Date(this.selectedTicket.startDateTime) 
        : this.today, Validators.required
      ],
      startTime: [
        this.selectedTicket.startDateTime ? 
        { 
          hour: new Date(this.selectedTicket.startDateTime).getHours(),
          minute: new Date(this.selectedTicket.startDateTime).getMinutes()
        } :
        {
          hour: 12, 
          minute: 0
        }
      ],
      endDate: [this.selectedTicket.endDateTime 
        ? new Date(this.selectedTicket.endDateTime)
        : this.weekFromToday, Validators.required
      ],
      endTime: [
        this.selectedTicket.endDateTime ?
        { 
          hour: new Date(this.selectedTicket.endDateTime).getHours(), 
          minute: new Date(this.selectedTicket.endDateTime).getMinutes()
        } :
        {
          hour: 12,
          minute: 0
        }
      ],
      organizationUnitId: [null, []]
    });

  }

  saveTicket(){
    if(this.createUpdateTicketForm.invalid){
      return;
    }
    this.isView = false;

    if(this.selectedTicket.id){
      
      this.taskService.update(this.selectedTicket.id, this.formTicketForUpdateData()).subscribe({
        next: () => {
          this.isModalOpen = false;
          this.createUpdateTicketForm.reset();
          this.list.get();
          this.calendarOptions.events = this.events;
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Ticket:Message:Edited:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:Edited:Error")
          );
        }
      })
    } else {
      this.taskService.create(this.formTicketData()).subscribe({
        next: () => {
          this.isModalOpen = false;
          this.list.get();
          this.createUpdateTicketForm.reset();
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Ticket:Message:Created:Success")
          )
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:Created:Error")
          )
        }
      })
    }
  }

  selectedNewTaskUsers(selectedUser: any) {
    if (selectedUser) {

      if(this.selectedUsers.totalCount > 0) {
        var isValuePresent = this.selectedUsers.items.find(x => x.userName == selectedUser.userName);
  
        if(isValuePresent){
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:User:Error:00001")
          );
    
          return;
        }
      }

      this.selectedUsers.items.push({userName: selectedUser.userName, id: selectedUser.id});

      this.selectedUsers.items = [...this.selectedUsers.items]
      this.selectedUsers.totalCount++;

      this.checkIfSelectedUsers();
    }
  }

  removeAssignedUser(eventValue: any) {
    this.selectedUsers.items.forEach((element, index) => {
      if(element.id == eventValue.id) {
        this.selectedUsers.items.splice(index, 1);
        this.selectedUsers.totalCount--;
        this.selectedUsers.items = [...this.selectedUsers.items];

        this.checkIfSelectedUsers();
      }
    });

  }

  isTicketForEdit(): boolean {
    if(this.selectedTicket.id != null){
      return true
    }

    return false;
  }

  checkIfSelectedUsers() {
    if(this.selectedUsers.totalCount == 0) {
      this.createUpdateTicketForm.controls["assignedUserId"].setValue(null);
      this.createUpdateTicketForm.controls["assignedUserId"].addValidators(Validators.required);
      this.createUpdateTicketForm.controls["assignedUserId"].updateValueAndValidity();
    } else {
      this.createUpdateTicketForm.controls["assignedUserId"].clearValidators();
      this.createUpdateTicketForm.controls["assignedUserId"].updateValueAndValidity();
    }
  }

  selectAllTenantUsers(){
    this.taskService.getTenantUserLookup().subscribe((response) => {
      this.selectedUsers.items = response.items;
      this.selectedUsers.totalCount = response.items.length;

      this.checkIfSelectedUsers();
    })
  }

  deleteTicket(ticketId: string) {
    this.confirmation.warn('::AreYouSureToDeleteTicket', '::AreYouSure').subscribe((status) => {
      if(status == Confirmation.Status.confirm){
        this.taskService.delete(ticketId).subscribe({
          next: () => {
            this.isModalOpen = false;
            this.list.get();
            this.createUpdateTicketForm.reset();
          },
          complete: () => {
            this.toaster.success(
              this.localizationService.instant("::Ticket:Message:Deleted:Success")
            );
          },
          error: () => {
            this.toaster.error(
              this.localizationService.instant("::Ticket:Message:Deleted:Error")
            );
          }
        })
      }
    })
  }
  
  formTicketData() {
    return {
      name: this.createUpdateTicketForm.get('name').value,
      description: this.createUpdateTicketForm.get('description').value,
      status: this.createUpdateTicketForm.get('taskStatus').value,
      ticketKind: this.createUpdateTicketForm.get('ticketKind').value,
      ticketEducationKind: this.createUpdateTicketForm.get('ticketEducationKind').value,
      assignedUserIdsList: this.selectedUsers.items.map(user => user.id),
      tenantCourseId: this.createUpdateTicketForm.get("course").value 
        ? this.createUpdateTicketForm.get("course").value.id : null,
      tenantDocumentId: this.createUpdateTicketForm.get("document").value 
        ? this.createUpdateTicketForm.get("document").value.id : null,
      startDateTime: this.datePipe.transform(this.getStartTime(), "yyyy-MM-ddTHH:mm:ss"),
      endDateTime: this.datePipe.transform(this.getEndTime(), "yyyy-MM-ddTHH:mm:ss")
    }
  }

  formTicketForUpdateData() {
    return {
      name: this.createUpdateTicketForm.get('name').value,
      description: this.createUpdateTicketForm.get('description').value,
      status: this.createUpdateTicketForm.get('taskStatus').value,
      ticketKind: this.createUpdateTicketForm.get('ticketKind').value,
      ticketEducationKind: this.createUpdateTicketForm.get('ticketEducationKind').value,
      assignedUserId: this.createUpdateTicketForm.get('assignedUserId').value,
      tenantCourseId: this.createUpdateTicketForm.get("course").value 
        ? this.createUpdateTicketForm.get("course").value.id : null,
      tenantDocumentId: this.createUpdateTicketForm.get("document").value 
        ? this.createUpdateTicketForm.get("document").value.id : null,
      startDateTime: this.datePipe.transform(this.getStartTime(), "yyyy-MM-ddTHH:mm:ss"),
      endDateTime: this.datePipe.transform(this.getEndTime(), "yyyy-MM-ddTHH:mm:ss")
    }
  }

  getStartTime(){
    let startDateTime = this.createUpdateTicketForm.get('startDate').value;
    startDateTime.setHours(this.createUpdateTicketForm.get('startTime').value.hour);
    startDateTime.setMinutes(this.createUpdateTicketForm.get('startTime').value.minute);
    return startDateTime
  }

  getEndTime(){
    let endDateTime = this.createUpdateTicketForm.get('endDate').value;
    endDateTime.setHours(this.createUpdateTicketForm.get('endTime').value.hour);
    endDateTime.setMinutes(this.createUpdateTicketForm.get('endTime').value.minute);
    return endDateTime
  }
}
